<template>
  <div class="mt-10 ">
    <div class="green--text text--darken-2 font-weight-bold mb-2 text-h6">
      {{ $t('courseContent') }}
    </div>

    <v-expansion-panels accordion focusable>
      <v-expansion-panel v-for="(section, idx) in sections" :key="section.id">
        <v-expansion-panel-header>
          <div class="d-flex flex-column justify-center ">
            <!-- 'القسم ' +
                  sectionNumber(idx + 1) +
                  ': ' + -->
            <div>
              {{ section[lang + 'title'] }}
            </div>
            <div class="caption">{{ section.duration }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expanel">
          <v-list nav dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(lecture, index) in section.lecture_id"
                :key="lecture.id"
                @click="setLecture(idx, index)"
              >
                <v-list-item-icon>
                  <v-icon v-text="'mdi-radiobox-marked'"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ lecture[lang + 'title'] }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text"
                    ><v-icon small class="ml-1">mdi-play-circle</v-icon
                    >{{ lecture.duration }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: ['sections'],
  data() {
    return {
      selectedLecture: 0,
      selectedSection: 0,
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    setLecture(sec, lec) {
      this.selectedSection = sec;
      this.selectedLecture = lec;
    },
    // sectionNumber(num) {
    //   if (num == 1) return 'الأول';
    //   else if (num == 2) return 'الثانى';
    //   else if (num == 3) return 'الثالث';
    //   else if (num == 4) return 'الرابع';
    //   else if (num == 5) return 'الخامس';
    //   else if (num == 6) return 'السادس';
    //   else if (num == 7) return 'السابع';
    //   else if (num == 8) return 'الثامن';
    //   else if (num == 9) return 'التاسع';
    //   else if (num == 10) return 'العاشر';
    // },
  },
};
</script>

<style></style>
